import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css';

const UserInfoModal = ({ onClose }) => {
  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    axios.get('/api/user-info', { withCredentials: true })
      .then(response => {
        setUserInfo(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the user info!", error);
      });
  }, []);

  return (
    <div className="modal">
      <div className="modal-content user-info-modal-content">
        <span className="close" onClick={onClose}>&times;</span>
        <h1>Account</h1>
        <p>View and update your account details</p>
        <div className="user-info">
          <label>
            <strong>Name</strong>
            <p>Will appear on receipts, invoices, and other communication</p>
            <input type="text" value={userInfo.username} readOnly />
          </label>
          <label>
            <strong>Email address</strong>
            <p>Used to sign in, for email receipts and product updates</p>
            <input type="text" value={userInfo.email} readOnly />
          </label>
          <label>
            <strong>Invoice Inbox Address</strong>
            <p>Inbox address for purchase invoices</p>
            <input type="text" value={userInfo.inbox_address} readOnly />
          </label>
          {userInfo.receipts_inbox_address && (
            <label>
              <strong>Receipts Inbox Address</strong>
              <p>Inbox address for receipts</p>
              <input type="text" value={userInfo.receipts_inbox_address} readOnly />
            </label>
          )}
          <label>
            <strong>Company ID</strong>
            <input type="text" value={userInfo.company_id} readOnly />
          </label>
          <label>
            <strong>License Key</strong>
            <input type="text" value={`xxxxxxx-${userInfo.license_key.slice(-12)}`} readOnly />
          </label>
        </div>
      </div>
    </div>
  );
};

export default UserInfoModal;

