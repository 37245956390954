import React, { useState } from 'react';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';

const ResetPassword = () => {
  const [username, setUsername] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [searchParams] = useSearchParams();

  const handleResetPassword = (e) => {
    e.preventDefault();
    const token = searchParams.get('token');

    if (newPassword !== confirmPassword) {
      setMessage('Passwords do not match');
      return;
    }

    axios.post('/api/reset-password', { token, username, new_password: newPassword })
      .then(response => {
        console.log('Password reset successful:', response.data);
        setMessage('Password reset successful. You can now log in with your new password.');
      })
      .catch(error => {
        console.error('Password reset error:', error);
        setMessage('Error resetting password. Please try again.');
      });
  };

  return (
    <div className="form-container">
      <h1>Reset Password</h1>
      <form onSubmit={handleResetPassword}>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Username"
          className="input-field"
          required
        />
        <input
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          placeholder="New Password"
          className="input-field"
          required
        />
        <input
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          placeholder="Confirm Password"
          className="input-field"
          required
        />
        <button type="submit" className="submit-button">Reset Password</button>
      </form>
      {message && <p className="message">{message}</p>}
    </div>
  );
};

export default ResetPassword;

