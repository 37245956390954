import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Login = ({ setLoggedIn }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [resetRequested, setResetRequested] = useState(false);
  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();
    axios.post('/api/login', { username, password }, { withCredentials: true })
      .then(response => {
        console.log('Login successful:', response.data);
        setError('');
        setLoggedIn(true);
        navigate(`/invoices/${response.data.company_id}`);
      })
      .catch(error => {
        console.error('Login error:', error);
        setError('Invalid credentials');
      });
  };

  const handleRequestReset = () => {
    axios.post('/api/request-reset-password', { email, username })
      .then(response => {
        console.log('Password reset email sent:', response.data);
        setError('');
        setSuccess('Password reset email sent. Please check your email, making sure to check your spam or junk folder.');
      })
      .catch(error => {
        console.error('Password reset error:', error);
        setError('Error sending password reset email');
      });
  };

  return (
    <div className="form-container">
      <h1>Login</h1>
      <form onSubmit={handleLogin}>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Username"
          className="input-field"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          className="input-field"
        />
        <button type="submit" className="submit-button">Login</button>
      </form>
      {error && <p className="error-message">{error}</p>}
      <button onClick={() => setResetRequested(!resetRequested)} className="reset-password-button">
        {resetRequested ? 'Cancel' : 'Forgot Password?'}
      </button>
      {resetRequested && (
        <div className="reset-password-container">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            className="input-field"
          />
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Username"
            className="input-field"
          />
          <button onClick={handleRequestReset} className="submit-button">Request Password Reset</button>
        </div>
      )}
      {success && <p className="success-message">{success}</p>}
    </div>
  );
};

export default Login;

