import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import Layout from './Layout';
import RegisterBasic from './RegisterBasic';
import RegisterAdvanced from './RegisterAdvanced';
import Login from './Login';
import InvoicesExpenses from './Invoices'; // Updated import statement
import ResetPassword from './ResetPassword';
import UserInfo from './UserInfo'; // Import the new UserInfo component
import axios from 'axios';
import './App.css';

const App = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [darkMode, setDarkMode] = useState(true);

  const toggleDarkMode = () => {
    setDarkMode(prevMode => !prevMode);
  };

  return (
    <div className={`App ${darkMode ? '' : 'light-mode'}`}>
      <Router>
        <LayoutWrapper loggedIn={loggedIn} setLoggedIn={setLoggedIn} toggleDarkMode={toggleDarkMode} darkMode={darkMode} />
      </Router>
    </div>
  );
};

const LayoutWrapper = ({ loggedIn, setLoggedIn, toggleDarkMode, darkMode }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    axios.post('/api/logout', {}, { withCredentials: true })
      .then(response => {
        console.log('Logout successful:', response.data);
        setLoggedIn(false);
        navigate('/login');
      })
      .catch(error => {
        console.error('Logout error:', error);
      });
  };

  return (
    <Layout loggedIn={loggedIn} toggleDarkMode={toggleDarkMode} handleLogout={handleLogout} darkMode={darkMode}>
      <Routes>
        <Route path="/register/basic" element={<RegisterBasic />} />
        <Route path="/register/advanced" element={<RegisterAdvanced />} />
        <Route path="/login" element={<Login setLoggedIn={setLoggedIn} />} />
        <Route path="/invoices/:companyId" element={<InvoicesExpenses />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/user-info" element={<UserInfo />} />
        <Route path="/" element={<Login setLoggedIn={setLoggedIn} />} />
      </Routes>
    </Layout>
  );
};

export default App;
