import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css';

const UserInfo = () => {
  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    axios.get('/api/user-info', { withCredentials: true })
      .then(response => {
        setUserInfo(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the user info!", error);
      });
  }, []);

  return (
    <div>
      <h1>Account</h1>
      <p>View your account details</p>
      <div className="user-info">
        <label>
          <strong>Username</strong>
          <input type="text" value={userInfo.username || ''} readOnly />
        </label>
        <label>
          <strong>Email address</strong>
          <p>Used to reset password</p>
          <input type="text" value={userInfo.email || ''} readOnly />
        </label>
        <label>
          <strong>Invoice Inbox Address</strong>
          <p>Inbox address for purchase invoices</p>
          <input type="text" value={`${userInfo.inbox_address || ''}@fwdap.io`} readOnly />
        </label>
        {userInfo.receipts_inbox_address && (
          <label>
            <strong>Receipts Inbox Address</strong>
            <p>Inbox address for receipts</p>
            <input type="text" value={`${userInfo.receipts_inbox_address}@fwdap.io`} readOnly />
          </label>
        )}
        <label>
          <strong>Company ID</strong>
          <p>Company ID for connected Quickbooks Online company</p>
          <input type="text" value={userInfo.company_id || ''} readOnly />
        </label>
        <label>
          <strong>License Key</strong>
          <p>License key for the current subscription</p>
          <input type="text" value={userInfo.license_key ? `xxxxxxx-${userInfo.license_key.slice(-12)}` : ''} readOnly />
        </label>
      </div>
    </div>
  );
};

export default UserInfo;

