import React, { useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import logo from './logo.png';
import {  FaSun, FaMoon, FaToggleOn, FaToggleOff, FaBars  } from 'react-icons/fa';
import UserInfoModal from './UserInfoModal';

const Layout = ({ children, loggedIn, toggleDarkMode, handleLogout, darkMode }) => {
  const { companyId } = useParams();
  const navigate = useNavigate();
  const [showUserInfo, setShowUserInfo] = useState(false);
  const [showBillingOverlay, setShowBillingOverlay] = useState(false);
  const [billingUrl, setBillingUrl] = useState('');

  React.useEffect(() => {
    const unprotectedRoutes = ['/register/basic', '/register/advanced', '/login', '/reset-password'];
    if (!loggedIn && !unprotectedRoutes.includes(window.location.pathname)) {
      navigate('/login');
    }
  }, [loggedIn, navigate]);

  const handleManageBilling = () => {
    setShowBillingOverlay(true);
    axios.get('/api/subscription_url', { withCredentials: true })
      .then(response => {
        if (response.data.url) {
          setBillingUrl(response.data.url);
        }
      })
      .catch(error => {
        console.error("There was an error fetching the subscription URL!", error);
      });
  };

  const handleUserInfo = () => {
    setShowUserInfo(true);
  };

  const closeUserInfoModal = () => {
    setShowUserInfo(false);
  };

  return (
    <div className="App">
      <header className="App-header">
        <div className="header-content">
          <a href="https://fwdap.io"><img src={logo} alt="Logo" className="logo" /></a>
          <button onClick={toggleDarkMode} className="toggle-mode">
            {darkMode ? <FaToggleOn size={24} /> : <FaToggleOff size={24} />}
          </button>
          {loggedIn && (
            <div className="nav-menu">
              <button className="nav-menu-button">
                <FaBars size={24} />
              </button>
              <div className="nav-menu-content">
                <button onClick={handleManageBilling}>Manage Billing</button>
                <button onClick={handleLogout}>Sign Out</button>
              </div>
            </div>
          )}
        </div>
      </header>
      {loggedIn && companyId && (
        <nav className="tab-links">
          <Link to={`/invoices/${companyId}`} className="tab-link">Invoices</Link>
          <Link to={`/expenses/${companyId}`} className="tab-link">Expenses</Link>
          <Link to={`/rules/${companyId}`} className="tab-link">Rules</Link>
          <button onClick={handleUserInfo} className="tab-link">My Account</button>
        </nav>
      )}
      <div className="content">
        {children}
      </div>
      {showBillingOverlay && (
        <div className="billing-overlay show">
          <div className="billing-content">
            <span className="billing-close" onClick={() => setShowBillingOverlay(false)}>&times;</span>
            <iframe src={billingUrl} title="Billing" width="100%" height="600px"></iframe>
          </div>
        </div>
      )}
      {showUserInfo && <UserInfoModal onClose={closeUserInfoModal} />}
    </div>
  );
};

export default Layout;
