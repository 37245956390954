import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const RegisterBasic = ({ prefilledEmail }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [email, setEmail] = useState(prefilledEmail || '');
  const [inboxAddress, setInboxAddress] = useState('');
  const [licenseKey, setLicenseKey] = useState('');
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleRegister = (e) => {
    e.preventDefault();
    axios.post('/api/register/basic', { username, password, confirm_password: confirmPassword, email, inbox_address: inboxAddress, license_key: licenseKey })
      .then(response => {
        setMessage(response.data.message);
        setMessageType('success');
        setOpen(true);
        setTimeout(() => {
          navigate('/login');
        }, 20000); // Navigate after 5 seconds
      })
      .catch(error => {
        setMessage(error.response.data.error);
        setMessageType('error');
        setOpen(true);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="form-container">
      <h1>Register</h1>
      <form onSubmit={handleRegister}>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Username"
          className="input-field"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          className="input-field"
        />
        <input
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          placeholder="Confirm Password"
          className="input-field"
        />
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          className="input-field"
        />
        <Tooltip title="This will be the email address to which you will forward PDF purchase invoices. Addresses are commonly formatted as ap.companyname@fwdap.io, but feel free to format it however you'd like.">
          <div className="input-container">
            <input
              type="text"
              value={inboxAddress}
              onChange={(e) => setInboxAddress(e.target.value)}
              placeholder="Inbox Address"
              className="input-field-short"
            />
            <span>@fwdap.io</span>
          </div>
        </Tooltip>
        <input
          type="text"
          value={licenseKey}
          onChange={(e) => setLicenseKey(e.target.value)}
          placeholder="License Key"
          className="input-field"
        />
        <button type="submit" className="submit-button">Register</button>
      </form>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{messageType === 'error' ? 'Error' : 'Success'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RegisterBasic;

